import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from '../components/Layout';
import SEO from "../components/SEO";
import Theme, { theme } from "@pagerland/themes/src/RealEstate";

import {
  Navbar,
  Register,
  WelcomeEmpty
} from "@pagerland/themes/src/RealEstate/containers";

import PropTypes from "prop-types";

export const query = graphql`
query CoursePage($slug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        dates
        thumb {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }`

export default function CourseDetails({ url, data }) {
  const pageBody = data.markdownRemark.html;
  const title = data.markdownRemark.frontmatter.title;
  const dates = data.markdownRemark.frontmatter.dates;
  const featuredImg = data.markdownRemark.frontmatter.thumb.childImageSharp.fluid;

  const section = [
    {
      ImgProps: {
        src: featuredImg.src,
        srcSet: featuredImg.srcSet,
      },
      title: title,
    }
  ];

  return (
    <Theme>
      <Helmet>
        <link href={theme.typography.googleFont} rel="stylesheet" />
        <meta name="theme-color" content={theme.colors.primary} />
      </Helmet>
      <SEO title={title} />
      <Layout>
        <Register name="register" title={title} courseName={title} courseDates={dates} />
        <div name="about" dangerouslySetInnerHTML={{ __html: pageBody }} />
      </Layout>
    </Theme>
  )
}

CourseDetails.propTypes = {
  url: PropTypes.string,
};

CourseDetails.defaultProps = {
  url: "https://www.instagram.com/practicalmontessori/",
};